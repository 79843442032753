import React from 'react';
import ProfileHeader from './ProfileHeader';
import Images from '../../assets/images/index';
import isAuth from '../../components/login/IsAuth';
import GlobalContext from '../../context/global-context';
import LogoBar from '../global/LogoBar';
import { Link } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import TopupWallet from './TopupWallet';
import ActivityIndicator from '../global/ActivityIndicator';
import { Translation } from 'react-i18next';
import RegisterRFID from './rfid/RegisterRFID';
import RFIDScreen from './rfid/RFIDScreen';
import GlobalSwitch from '../global/Switch';
import UpdateDetails from './UpdateDetails';
import ReturnBalance from './ReturnBalance';
import StatusBarPadding from '../../common/StatusBarPadding';
import AutoTopupScreen from './AutoTopupScreen';

class ProfileScreen extends React.Component {

    static contextType = GlobalContext;


    constructor(props){
      super(props);

      this.state = {
        dialogUnitOpen: false,
        dialogPreferencesOpen: false,
        dialogMethodOpen: false,
        dialogLangOpen: false,
        dialogCurOpen: false,
        showChangePassword: false,
        showTopupWallet: false,
        showEditDetails: false,
        showRegisterRFID: false,
        showRFIDCards: false,
        showReturnBalance: false,
        showAutoTopUp: false,
        removingCard: false,
        availableLanguages: [
          {
            lang: 'en',
            label: 'English'
          },
          {
            lang: 'se',
            label: 'Svenska'
          },
          // {
          //   lang: 'cy',
          //   label: 'Cymraeg'
          // }
        ]
      } 
    }

    logout = () => {
      this.context.userLogout()
        .then(result => {
          this.props.history.push('/');
          // if using isAuth then the previous screen should appear
        })
        .catch(err => {
          console.log('There was an error:' + err)
        })
    }

    unitToggle = (unit) => {
      if(this.context.account.preferences.unit !== unit)
        this.context.unitToggle();

      this.closeUnitDialog();
    }

    removeCard = () => {
      this.setState({removingCard: true}, () => {
        this.context.userWalletRemoveCard().then(
          res => {
            this.setState({removingCard: false});
          },
          err => {
            this.setState({removingCard: false});
          }
        )
      })
    }

    closeUnitDialog = () => {
      this.setState({dialogUnitOpen: false});
    }

    openUnitDialog = () => {
      this.setState({dialogUnitOpen: true});
    }

    closePreferencesDialog = () => {
      this.setState({dialogPreferencesOpen: false});
    }

    openPreferencesDialog = () => {
      this.setState({dialogPreferencesOpen: true});
    }


    methodToggle = (method) => {
      if(this.context.account.preferences.paymentMethod !== method)
        this.context.paymentMethodToggle();

      this.closeMethodDialog();
    }

    closeMethodDialog = () => {
      this.setState({dialogMethodOpen: false});
    }

    openMethodDialog = () => {
      this.setState({dialogMethodOpen: true});
    }

    

    langToggle = (lang) => {
      if(this.context.account.preferences.lang !== lang)
        this.context.languageToggle(lang);

      this.closeLangDialog();
    }

    closeLangDialog = () => {
      this.setState({dialogLangOpen: false});
    }

    openLangDialog = () => {
      this.setState({dialogLangOpen: true});
    }

    handleAddRFID = () => {
      this.setState({showRegisterRFID: true});
    }

    closeCurDialog = () => {
      this.setState({dialogCurOpen: false});
    }

    openCurDialog = () => {
      this.setState({dialogCurOpen: true});
    }

    closeAutoDialog = () => {
      this.setState({showAutoTopUp: false});
    }

    openAutoDialog = () => {
      this.setState({showAutoTopUp: true});
    }

    switchCurrency = (currency) => {
      if(parseFloat(this.context.wallet.balance) <= 0){
        this.context.userWalletSwitchCurrency(currency);
        this.closeCurDialog();
      }
    }


    render() {
        return (
          <Translation>
            {t => 
            <div style={styles.container}>
              {!this.context.isNative && <LogoBar />}
              {this.context.isNative && <StatusBarPadding platform={this.context.platform} />}

              <ProfileHeader onOpenDrawer={this.onOpenDrawerHandler} history={this.props.history} title={t('TITLE_PROFILE')} />

              <div style={styles.wrapper}>

                <div style={styles.vehicle}>
                    <span style={styles.vehicleLabel}>{this.context.getVehicleReg()}</span>
                    <Link to='/vehicles' style={styles.vehicleLink}>{this.context.account.vehicles.length === 0 ? t('ADD_VEHICLE') : t('CHANGE')}</Link>
                </div>

                <div style={styles.wallet}>
                  <div style={styles.donut}>
                    <div style={styles.bolt} onClick={this.context.userWalletGet}><img src={Images.bolt} style={{width: 23, height: 36}} alt="Balance" /></div>
                    {!this.context.loading.wallet && <div style={styles.balance}>
                      <span style={styles.amount}>{parseFloat(this.context.wallet.balance).toLocaleString(navigator.language, {style: 'currency', currency: this.context.wallet.currency})}</span>
                      <span style={styles.amount_desc}>{t('CURRENT_BALANCE')}</span>
                    </div>}
                    {this.context.loading.wallet && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}
                  </div>
                </div>

                <div style={styles.topup}>
                  <a href="JavaScript:void(0);" style={styles.topupButton}>
                    <span style={styles.topupButtonText} onClick={() => this.setState({showTopupWallet: true})}>{t('TOPUP')}</span>
                  </a>
                </div>


                <div style={styles.menuWrapper}>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabel}>{t('PAYMENT_METHOD')}</span>
                    <span style={styles.menuValueLink} onClick={this.openMethodDialog}>{this.context.account.preferences.paymentMethod==='WALLET'?t('WALLET')+(!this.context.wallet.card?'':' (**'+this.context.wallet.card+')'):t('PAYG')}</span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabel}>{t('DEFAULT_CURRENCY')}</span>
                    <span style={styles.menuValueLink} onClick={this.openCurDialog}>{this.context.wallet.currency}</span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabel}>{t('AUTO_WALLET_TOP_UP')}</span>
                    <span style={styles.menuValueLink} onClick={this.openAutoDialog}>{this.context.wallet.autoTopUpEnabled ? t('ON') : t('OFF')}</span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabel}>{t('UNIT')}</span>
                    <span style={styles.menuValueLink} onClick={this.openUnitDialog}>{this.context.account.preferences.unit==='m'?t('MILES'):t('KM')}</span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabel}>{t('LANGUAGE')}</span>
                    <span style={styles.menuValueLink} onClick={this.openLangDialog}>{t('LANG')}</span>
                  </div>

                  <div style={styles.menuItem}>
                    <span style={styles.menuLabelLink} onClick={() => this.setState({showEditDetails: true})}><img src={Images.edit_icon} style={styles.menuLabelLinkIcon} alt={t('UPDATE_DETAILS')} />{t('UPDATE_DETAILS')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                  <div style={Object.assign({}, styles.menuItem, {cursor: 'pointer'})}>
                    <span style={styles.menuLabelLink} onClick={() => this.setState({showRFIDCards: true})}><img src={Images.rfid_icon} style={styles.menuLabelLinkIcon} alt={t('RFID_TAGS')} />{t('RFID_TAGS')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                  <div style={Object.assign({}, styles.menuItem, {cursor: 'pointer'})}>
                    <span style={styles.menuLabelLink} onClick={() => this.setState({showChangePassword: true})}><img src={Images.password_icon} style={styles.menuLabelLinkIcon} alt={t('CHANGE_PASSWORD')} />{t('CHANGE_PASSWORD')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabelLink} onClick={() => this.setState({showReturnBalance: true})}><img src={Images.withdraw_icon} style={styles.menuLabelLinkIcon} alt={t('RETURN_WALLET_BALANCE')} />{t('RETURN_WALLET_BALANCE')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                  <div style={styles.menuItem}>
                    <span style={styles.menuLabelLink} onClick={this.openPreferencesDialog}><img src={Images.notifications_icon} style={styles.menuLabelLinkIcon} alt={t('MARKETING_PREFERENCES')} />{t('MARKETING_PREFERENCES')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                  <div style={Object.assign({}, styles.menuItem, {cursor: 'pointer'})}>
                    <span style={styles.menuLabelLink} onClick={this.logout}><img src={Images.logout_icon} style={styles.menuLabelLinkIcon} alt={t('SIGN_OUT')} />{t('SIGN_OUT')}</span>
                    <span style={styles.menuValue}></span>
                  </div>
                </div>

              </div>

              

              {this.state.dialogUnitOpen && <div style={styles.dialogBg} onClick={this.closeUnitDialog}></div>}
              <div style={Object.assign({}, styles.dialog, this.state.dialogUnitOpen && styles.dialogIn)} className="animate">
                <div style={styles.dialogWrapper}>
                    <span style={styles.dialogHeader}>{t('UNIT')}</span>
                    <div style={styles.dialogBody}>
                      <div style={styles.dialogUnit} onClick={() => {this.unitToggle('m')}}>
                        <div style={styles.dialogIcon}>
                          {this.context.account.preferences.unit === 'm'?<img src={Images.bullet_full} style={{width: 20, height: 20}}  alt="Bullet"/>:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                        </div>
                        <div style={styles.detail}>
                          {t('MILES')}
                        </div>
                      </div>
                      <div style={styles.dialogUnit} onClick={() => {this.unitToggle('k')}}>
                        <div style={styles.dialogIcon}>
                          {this.context.account.preferences.unit === 'k'?<img src={Images.bullet_full} style={{width: 20, height: 20}}  alt="Bullet"/>:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                        </div>
                        <div style={styles.detail}>
                          {t('KM')}
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              

              {this.state.dialogMethodOpen && <div style={styles.dialogBg} onClick={this.closeMethodDialog}></div>}
              <div style={Object.assign({}, styles.dialog, this.state.dialogMethodOpen && styles.dialogIn)} className="animate">
                <div style={styles.dialogWrapper}>
                    <span style={styles.dialogHeader}>{t('PAYMENT_METHOD')}</span>
                    <div style={styles.dialogBody}>
                      <div style={styles.dialogUnit} onClick={() => {this.methodToggle('PAYG')}}>
                        <div style={styles.dialogIcon}>
                          {this.context.account.preferences.paymentMethod !== 'WALLET'?<img src={Images.bullet_full} style={{width: 20, height: 20}} alt="Bullet" />:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                        </div>
                        <div style={styles.detail}>
                          {t('PAYG')}
                        </div>
                      </div>
                      <div style={styles.dialogUnit}>
                        <div style={styles.dialogIcon} onClick={() => {this.methodToggle('WALLET')}}>
                          {this.context.account.preferences.paymentMethod === 'WALLET'?<img src={Images.bullet_full} style={{width: 20, height: 20}} alt="Bullet" />:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                        </div>
                        <div style={styles.detail}>
                          <span onClick={() => {this.methodToggle('WALLET')}}>{t('WALLET')}</span> 
                          {this.context.wallet.card.length === 4 && 
                            <span>
                              <span> (**{this.context.wallet.card})</span>
                              {!this.state.removingCard && <span onClick={this.removeCard}><img src={Images.remove_btn} style={{ width: 18, height: 18, verticalAlign: 'bottom' }} alt="Remove" /></span>}
                              {this.state.removingCard && <div style={styles.tinyLoading}><ActivityIndicator /></div>}
                            </span>
                          }
                        </div>
                      </div>

                    </div>
                </div>
              </div>

              

              {this.state.dialogLangOpen && <div style={styles.dialogBg} onClick={this.closeLangDialog}></div>}
              <div style={Object.assign({}, styles.dialog, this.state.dialogLangOpen && styles.dialogIn)} className="animate">
                <div style={styles.dialogWrapper}>
                    <span style={styles.dialogHeader}>{t('LANGUAGE')}</span>
                    <div style={styles.dialogBody}>


                      {this.state.availableLanguages.map((l, idx) => (
                      
                        <div key={idx} style={styles.dialogUnit} onClick={() => {this.langToggle(l.lang)}}>
                          <div style={styles.dialogIcon}>
                            {(this.context.account.preferences.lang === l.lang)?<img src={Images.bullet_full} style={{width: 20, height: 20}} alt="Bullet" />:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                          </div>
                          <div style={styles.detail}>
                            {l.label}
                          </div>
                        </div>

                      ))}

                    </div>
                </div>
              </div>

              

              {this.state.dialogCurOpen && <div style={styles.dialogBg} onClick={this.closeCurDialog}></div>}
              <div style={Object.assign({}, styles.dialog, this.state.dialogCurOpen && styles.dialogIn)} className="animate">
                <div style={styles.dialogWrapper}>
                    <span style={styles.dialogHeader}>{t('DEFAULT_CURRENCY')}</span>
                    <div style={styles.dialogBody}>
                      {parseFloat(this.context.wallet.balance) > 0 && <span style={styles.dialogWarn}>{t('CURRENCY_CHANGE_WARN')}</span>}
                      {this.context.currencies.map(cur => (
                        <div key={cur.Code} style={styles.dialogUnit} onClick={() => {this.switchCurrency(cur.Code)}}>
                          <div style={styles.dialogIcon}>
                            {this.context.wallet.currency === cur.Code?<img src={Images.bullet_full} style={{width: 20, height: 20}} alt="Bullet" />:<img src={Images.bullet} style={{width: 20, height: 20}} alt="Bullet" />}
                          </div>
                          <div style={styles.detail}>
                            {cur.Code} ({cur.Symbol})
                          </div>
                        </div>
                      ))}
                    </div>
                </div>
              </div>

              

              {this.state.dialogPreferencesOpen && <div style={styles.dialogBg} onClick={this.closePreferencesDialog}></div>}
              <div style={Object.assign({}, styles.dialog, this.state.dialogPreferencesOpen && styles.dialogIn)} className="animate">
                <div style={styles.dialogWrapper}>
                    <span style={styles.dialogHeader}>{t('MARKETING_PREFERENCES')}</span>
                    <span style={styles.dialogSubHeader}>{t('MARKETING_PREFERENCES_DESC')}</span>
                    <div style={styles.dialogBody}>

                      <div style={styles.filterItem}>
                        <span style={styles.filterItemLabel}>{t('EMAIL')}</span>
                        <div>
                          <GlobalSwitch checked={this.context.user.profile.user.MarketingEmail !== null} id={'marketing-email'} onChange={() => {this.context.marketingPreferenceToggle('email')}}/>
                        </div>
                      </div>
                      <div style={styles.filterItem}>
                        <span style={styles.filterItemLabel}>{t('SMS')}</span>
                        <div>
                          <GlobalSwitch checked={this.context.user.profile.user.MarketingSMS !== null} id={'marketing-sms'} onChange={() => {this.context.marketingPreferenceToggle('sms')}}/>
                        </div>
                      </div>
                      <div style={styles.filterItem}>
                        <span style={styles.filterItemLabel}>{t('PUSH')}</span>
                        <div>
                          <GlobalSwitch checked={this.context.user.profile.user.MarketingPush !== null} id={'marketing-push'} onChange={() => {this.context.marketingPreferenceToggle('push')}}/>
                        </div>
                      </div>

                    </div>
                </div>
              </div>

              <ChangePassword show={this.state.showChangePassword} close={() => this.setState({showChangePassword: false})} />
              <TopupWallet show={this.state.showTopupWallet} close={() => this.setState({showTopupWallet: false})} />
              <AutoTopupScreen show={this.state.showAutoTopUp} close={() => this.setState({showAutoTopUp: false})} />
              <UpdateDetails show={this.state.showEditDetails}  close={() => this.setState({showEditDetails: false})} />
              <ReturnBalance show={this.state.showReturnBalance} close={() => this.setState({showReturnBalance: false})} />
              <RegisterRFID show={this.state.showRegisterRFID} close={() => this.setState({showRegisterRFID: false})} />
              <RFIDScreen show={this.state.showRFIDCards} close={() => this.setState({showRFIDCards: false})} addRFIDCard={this.handleAddRFID} />
            </div>
            }
          </Translation>
        );
    }
}

export default isAuth(ProfileScreen);

const styles = {
  container: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  wrapper: {
    backgroundColor: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto'
  },
  vehicle: {
    width: '100%',
    display: 'flex',
    height: 50,
    flexWrap:'wrap',
    alignItems: 'center',
    flexDirection: 'row',
  },
  number_plate: {
      flex: 1,
      paddingLeft: 20,
      fontSize: 18,
      fontWeight: '400',
      color: '#484848',
  },
  model: {
      flex: 1,
      textAlign: 'right',
      paddingRight: 20,
      fontSize: 18,
      fontWeight: '400',
      color: '#484848',
  },
  wallet: {
    width: '100%',
    display: 'flex',
    minHeight: 220,
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  donut: {
    width: 180,
    height: 180,
    borderWidth: 10,
    borderStyle: 'solid',
    display: 'flex',
    borderColor: '#6CB33F',
    borderRadius: 140,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  bolt: {
    width: 50,
    height: 50,
    position: 'absolute',
    top: -35,
    left: 65,
    backgroundColor: '#fff',
    borderRadius: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  balance: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  amount:{
    fontSize: 38,
    fontWeight: '600',
    color: '#484848',
  },
  amount_desc:{
    fontSize: 14,
    color: '#888'
  },
  topup: {
    display: 'flex',
    height: 40,
    marginTop: 20,
    marginBottom: 40,
    alignItems: 'center',
    justifyContent: 'center'
  },
  topupButton: {
    backgroundColor: '#464646',
    height: 36,
    minWidth: 80,
    padding: '0 10px',
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    lineHeight: 1
  },
  topupButtonText: {
    color: '#fff',
    textAlign: 'center'
  },
  vehicle: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  vehicleLabel: {
      flex: 1,
      fontWeight: 700,
  },
  vehicleLink: {
    flex: 1,
    textAlign: 'right',
    fontWeight: 700,
    color: '#6CB33F'
  },
  menuWrapper: {
    paddingRight: 20,
    paddingLeft: 20
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    height: 50,
    flexWrap:'wrap',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: '#efefef',
    alignItems: 'center',
    flexDirection: 'row',
  },
  menuLabel: {
    flex: 1,
    fontSize: 16,
  },
  menuValue: {
    fontSize: 16,
    textAlign: 'right'
  },
  menuValueLink: {
    fontSize: 16,
    flex: 1,
    textAlign: 'right',
    fontWeight: 700,
    color: '#6CB33F'
  },
  menuLabelLink: {
    fontSize: 16,
    flex: 1,
    fontWeight: 700,
    color: '#6CB33F',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  menuLabelLinkIcon: {
    height: 28,
    width: 28,
    marginRight: 10
  },
  dialogBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0,0,0,.3)',
    zIndex: 989
  },
  dialog: {
    display: 'block',
    width: 280,
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: '#fff',
    boxShadow: '0 0 6px rgba(0,0,0,.3)',
    transform: 'translate(-50%, 100%)',
    visibility: 'hidden',
    opacity: 0,
    zIndex: 990,
    borderRadius: 4
  },
  dialogIn: {
    transform: 'translate(-50%, -50%)',
    visibility: 'visible',
    opacity: 100,
  },
  dialogWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },
  dialogHeader: {
    fontSize: 18,
    fontWeight: 900,
    marginTop: 15,
    paddingLeft: 20,
    paddingRight: 20
  },
  dialogSubHeader: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 5,
    paddingLeft: 20,
    paddingRight: 20
  },
  dialogBody: {
    flex: 1,
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  dialogWarn: {
    fontSize: 12,
    color: '#cca200'
  },
  dialogUnit: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  dialogIcon: {
    marginRight: 20
  },
  loadingWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#d4d4d4',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tinyLoading: {
    backgroundColor: '#d4d4d4',
    opacity: .7,
    borderRadius: 25,
    height: 20,
    width: 20,
    display: 'inline-block',
  },
  filterItem: {
      width: '100%',
      display: 'flex',
      height: 50,
      borderBottomWidth: 1,
      borderBottomColor: '#efefef',
      borderBottomStyle: 'solid',
      alignItems: 'center',
      flexDirection: 'row',
  },
  filterItemLabel: {
      flex: 1,
      fontSize: 16
  }
};
