const accountReducer = (state = {
    status: 'LOADING',
    msg: '',
    data: {
        charges: [],
        favorites: [],
        preferences: {
          showPublic: true,
          showPrivate: true,
          availableOnly: true,
          unit: 'm',
          paymentMethod: 'PAYG',
          lang: 'en'
        },
        vehicles: []
    },
    prevData: null
}, action) => {
    let current_data = JSON.parse(JSON.stringify(state.data));
    const status = typeof action.status !== 'undefined' ? action.status.toUpperCase(): null;

    switch(action.type){
        case "ACCOUNT_UPDATE": 
            state = {
                ...state,
                status: action.status,
                msg: action.status === 'error'?action.payload:'',
                data: action.status === 'OK'?{...current_data, ...action.payload, preferences: {...current_data.preferences, ...action.payload.preferences}}:current_data,
                prevData: JSON.parse(JSON.stringify(current_data))
            }
        break
        default:
    }
    return state;
}

export default accountReducer;