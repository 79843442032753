import React, { useEffect } from 'react';
import Images from '../../assets/images/index';
import Header from '../global/Header';
import GlobalContext from '../../context/global-context';
import ActivityIndicator from '../global/ActivityIndicator';
import Helpers from '../../context/helpers';

import SelectTime from './SelectTime';
import NumberPlateInput from './NumberPlateInput';
import PaymentMethodSelect from './PaymentMethodSelect';

// Dialogs
import LoadManagedDialog from './LoadManagedDialog';
import WalletInfoDialog from './WalletInfoDialog';
import ErrorDialog from './ErrorDialog';
import SignInOrGuest from './SignInOrGuest';
import TopupWallet from '../profile/TopupWallet';
import PaymentWindow from './PaymentWindow';
import ActiveCharge from './ActiveCharge';
import { Translation } from 'react-i18next';
import SelectKwhLimit from './SelectKwhLimit';
import OperationalHours from '../../common/OperationalHours';
import NotificationAccordian from '../../common/NotificationAccordian';



/*
  socketState:
  U = unavailable
  A = Available
  F = Fault
  C = Charging
*/

const defaultState = {
  socketState: 'U',
  loading: true,
  initialised: false,
  show: {
    selectTime: false,
    checkoutMethod: false,
    paymentMethod: false,
    paymentWindow: false,
    confirmedCharge: false,
    regNumber: false,
    loadManager: false,
    topupWallet: false,
    walletInfo: false,
    kwhLimit: false,
    error: false,
    operationalHours: false
  },
  session: null,
  fields: {
    time: 0,
    vrm: '',
    paymentMethod: 'PAYG',
    kwhLimit: null
  },
  required: {
    time: true,
    vrm: false,
    paymentMethod: true,
    kwhLimit: false
  },
  errors: {
    time: false,
    wallet: false,
    paymentMethod: false,
    kwhLimit: false,
    currency: false
  },
  valid: false,
  confirmPressed: false,
  isFree: false,
  paymentUrl: null
}

class SocketScreen extends React.Component {

  abortController = new AbortController()

  static contextType = GlobalContext

  constructor(props){
    super(props);

    this.state = {
      id: parseInt(this.props.match.params.site),
      point_id: parseInt(this.props.match.params.point),
      connector_no: this.props.match.params.connector,
      payg_code: this.props.match.params.paygcode,
      site: null,
      point: null,
      connector: null,
      isGroupManager: parseInt(this.props.match.params.point)<1000?true:false,
      ...defaultState
    }

  }


  // Decides if a socket is idle or not based on number of connectors and point status.
  _isSocketAvailable = () => {

    // The connectors
    const conLen = this.state.site.connectors.filter(c => c.pointId === this.state.point_id).length;

    //
    //  If there's more than one connector per charge point id then
    //  availabilty is determined if the point status is charging and
    //  the connector is connected, otherwise it is available
    //
    //  If connector to charge point is one to one then go by the charge
    //  point status
    //
    if(typeof this.state.point === 'undefined')
      return false;

    if((conLen > 1 && this.state.point.status === 'CHARGING' && this.state.connector.status !== 'CONNECTED') || this.state.point.status === 'IDLE'){
      return true;
    } else {
      return false;
    }
  }

  getMarkerType = () => {
    const connectors = this.state.site.connectors.filter(c => c.pointId === this.state.point_id);
    const conLen = typeof connectors !== 'undefined'?connectors.length:0;

    if(conLen < 2 && (this.state.connector.status === 'CONNECTED' || this.state.point.status === 'CHARGING')){
      return Helpers.markerTypes.inuse;
    } else if(conLen > 1 && (this.state.connector.status === 'CONNECTED' && this.state.point.status === 'CHARGING')){
        return Helpers.markerTypes.inuse;
    } else if(this.state.site.privacy.public === false){
       return Helpers.markerTypes.out;
    } else if(this._isSocketAvailable(this.state.site)) {
      return Helpers.markerTypes.open;
    } else {
      return Helpers.markerTypes.fault;
    }
  }





  //
  //
  //  Mount / Unmount
  //
  //

  componentDidMount(){

    this._init();


    /*
    *
    * When the user gets loaded their profile might have different options for charging,
    * so we need to reRun the chargepointwaiting or setendtime functions.
    *
    */
    window.addEventListener('user', () => this.nudgeUserDefaults());
    window.addEventListener('wallet', () => this.nudgeUserDefaults());


    /*
    *
    * When the charge sessions is reloaded check if there is an active 
    * charge session and it is theirs?
    *
    */
    window.addEventListener('sessions', () => {

      if(this._isActiveCharge())
        this.setState({socketState: 'C'});

    })

    this.context.setSessionsQuickMode(true);

  }

  componentWillUnmount(){
    this.abortController.abort();
    window.removeEventListener('user', null, false);
    window.removeEventListener('wallet', null, false);
    window.removeEventListener('sessions', null, false);

    this.context.setSessionsQuickMode(false);
    clearTimeout(this._checkChargeSessionStatusTimeout);
  }


  nudgeUserDefaults = () => {


    this._setUserDefaults(() => {

      if(this.state.point !== null && !this.state.confirmPressed && this._isSocketAvailable())
        this._isChargeSessionWaiting();
      
      // If the charge point wasn't loaded it may be a private point and this may be a full
      // page reload.
      if(typeof this.state.point === 'undefined' || this.state.point === null || typeof this.state.connector === 'undefined' || this.state.connector === null)
        this.context.fetchSite(this.state.id, this.abortController)
          .then((site) => { 
            this._loadSocket(site, true);
          })
          .catch((error) => {
          });

    });

  }




  //
  //
  //  Charge session lifecycle
  //
  //

  
  //
  //
  //  INIT
  //  Runs on first load, gathers together all the default parts to construct a session 
  //

  _init = async () => {


    // Load in the site data which includes point and connector data
    this.setState({loading: true});

    let isInit = true;

    // If a site has been pre-loaded into the global state
    if(this.context.site !== null && this.context.site.id === this.state.id){
      this._loadSocket(this.context.site, isInit);
      isInit = false;
    }

    this.context.fetchSite(this.state.id, this.abortController)
      .then((site) => { 

        this._loadSocket(site, isInit);

      })
      .catch((error) => {
      });

    // Reload the wallet for accurate frontend wallet validation
    await this.context.userWalletGet();
    this._setUserDefaults();

  }

  

  _setUserDefaults = (callback = () => {}) => {

    // Check the vehicle registration
    let vrm = this.context.getVehicleReg();
    if(this._hasVRMBeenSet())
      vrm = false;

    this.setState({
      fields: {
        ...this.state.fields,
        vrm: !vrm?this.state.fields.vrm:vrm,
        paymentMethod: this.state.confirmPressed?this.state.fields.paymentMethod:this._defaultPaymentMethod()
      }
    }, callback)

  }

  _defaultPaymentMethod = () => {

    // Default to PAYG
    let defaultPay = 'PAYG';

    // Not Group Manager and User is Logged In
    if(!this.state.isGM && this.context.isLoggedIn) {

      // If a preference has been set
      if(typeof this.context.account.preferences.paymentMethod !== 'undefined')
        defaultPay = this.context.account.preferences.paymentMethod;

      // If there is a positive wallet balance always set to wallet
      if(parseFloat(this.context.wallet.balance) > 0)
        defaultPay = 'WALLET';
    }

    return defaultPay;

  }


  //
  //  The site has been loaded, extract the charge point and socket info
  //
  _loadSocket = (site, isInit = false) => {
    
    // Get the charge point from the site
    const point = site.points.find(p => p.id == this.state.point_id);

    // If the connector number has been specified use it, or default to 1
    const connectorNo = typeof this.state.connector_no !== 'undefined'?this.state.connector_no:1;

    //  Get the connector from the point id and connector number
    const connector = site.connectors.find(c => c.pointId == this.state.point_id && c.number == connectorNo);

    // If the user has a default vehicle set then use it
    let vrm = this.context.getVehicleReg();
    if(!vrm)
      vrm = '';
    
    if(typeof point !== 'undefined')
      this.setState({ 
        connector: connector, 
        point: point, 
        site: site,
        loading: false,
        fields: {
          ...this.state.fields,
          vrm: (isInit || !this._hasVRMBeenSet())?vrm:this.state.fields.vrm
        }
      }, () => {

        if(isInit){
          if(this._isSocketAvailable()){
            this._isChargeSessionWaiting();
          } else {
            this.setState({socketState: this._isActiveCharge()?'C':'U', initialised: true});
          }
        } else {
          this.setState({initialised: true});
        }

      });
  }

  _isActiveCharge = () => {

    let result = false;
    if(typeof this.context.account.charges !== 'undefined'){

      const connector_no = typeof this.state.connector_no !== 'undefined'?this.state.connector_no:1;
      const sessions = this.context.account.charges.filter(s => s.EvPointSiteID==this.state.id && s.EvPoint == this.state.point_id && s.Connector == connector_no);

      for(let i=0; i<sessions.length; i++){
        const s = sessions[i];
        const eDate = Helpers.SQLtoUTCDate(s.EndTime);
        // const bits = s.Time.split(':');
        // eDate.setHours(eDate.getHours()+parseInt(bits[0]));
        // eDate.setMinutes(eDate.getMinutes()+parseInt(bits[1]));
        // eDate.setSeconds(eDate.getSeconds()+parseInt(bits[2]));
        const now = new Date();
        if(eDate>now){
          result = true;
          break;
        }
      }
    }
    return result;

  }


  _isChargeSessionWaiting = () => {

    this.context.isChargePointWaiting(this.state.point.id, this.state.payg_code)
      .then(session => {

        // This will tell the response to also confirm the charge time
        const currentSession = {...this.state.session};
        let confirmChargeTime = false;

        // This is to detect if isChargePointWaiting is being 
        // called after a charge is already partially set in the 
        // app e.g. if they have already selected hours or entered
        // a VRM
        if(currentSession !== null && currentSession.Hours > 0){
          session.Hours = currentSession.Hours;
          session.VRM = this.state.fields.vrm !== null?this.state.fields.vrm.replace(/\s/g,''):null
          confirmChargeTime = true;
        }

        // Check this after the check for current charge
        // because kwh charges need to overwrite their
        // hours to 12
        if(session.UnitOfMeasureType === 'kwh'){
          session.Hours = 504
          session.Price = null
          session.VRM = this.state.fields.vrm !== null?this.state.fields.vrm.replace(/\s/g,''):null
          confirmChargeTime = true;
        }
        
        this.setState({
          session: session, 
          required: {
            ...this.state.required,
            vrm: session.VRMRequired
          }, 
          socketState: this._isSocketAvailable()?'A':'U',
          initialised: true
        }, 
        () => {

           if(confirmChargeTime === true)
             this._selectSession();
        });
      })
      .catch(err => {
        this.setState({socketState: this._isActiveCharge()?'C':'U', initialised: true}, () => {
          //console.log(this.state.socketState);
        });
      })

  }


  //
  //  Confirm the charge session and return a price
  //

  _selectSession = () => {

    let newSession = {
      ...this.state.session,
      Price: null,
      Hours: this.state.session.UnitOfMeasureType === 'kwh'?this.state.session.Hours:this.state.fields.time,
      VRM: this.state.fields.vrm !== null?this.state.fields.vrm.replace(/\s/g,''):null,
      paymentMethod: this.state.isGM?'PAYG':this.state.fields.paymentMethod
    }

    this.setState({loading: true}, () => {

      if(this.state.required.vrm && this.state.fields.vrm.length < 2){

        //console.log('no VRM')

        // We cannot call it but hold on
        this.setState({
          session: newSession,
          isFree: false,
          loading: false
        }, () => {
          this.validate()
        })

      } else {
  
        this.context.selectCharge(newSession)
          .then(session => {


            // Determine if the charge session is free or not
            let isFree  = false;
            if((session.Price <= 0 && session.UnitOfMeasureType !== 'kwh' && session.Hours > 0) || (session.Tariff <= 0 && session.UnitOfMeasureType === 'kwh'))
              isFree = true;

            this._setError('time', false);
            
            this.setState({
              session: session,
              isFree: isFree,
              loading: false
            }, () => {
              this.validate()
            })

          })
          .catch((err) => {
            const session = {
              ...this.state.session
            }

            this._setError('time', err);

            this.setState({
              loading: false,
              session: session,
              isFree: false,
            });
            
          })

      }

    })
  }



  //
  //  Confirm the actual charge session, get a payment url or confirmation and display either the payment or connection screens
  //

  confirmCharge = () => {
    this.setState({confirmPressed: true});
    this.closeError();


    if(this.state.valid && (this.context.isLoggedIn || this.context.isGuestCheckout || this.state.isFree)){
      //console.log('confirming valid charge')

      this.setState({paymentUrl: null, loading: true}, () => {

        let newSession = {
          ...this.state.session,
          VRM: this.state.fields.vrm,
          paymentMethod: this.state.isGM?'PAYG':this.state.fields.paymentMethod
        }

        this.context.confirmCharge(newSession)
        .then(result => {

          if(result === 'FREE'){
            // This is a free vend so check for authorised and start the charge
            this._checkChargeSessionStatus();
            this.setState({loading: false}); 

          } else if (result.indexOf('WALLET') > -1) {

            // This is payment via the wallet and so doesn't require the payment page.
            const unit = result.split(' ')[1];
            if(unit.toUpperCase() === 'KWH' || unit.toUpperCase() === 'TIMED')
              this._checkChargeSessionStatus();
            
            this.setState({loading: false}); 

          } else {
            //console.log(result);
            this.setState({
              paymentUrl: result, 
              show: {
                ...this.state.show,
                paymentWindow: true
              },
              loading: false
            }); 
          }

        })
        .catch(err => {
          this.setState({loading: false})
          this.showError();
          //console.log(err)
        })
      })
    }
  }



  //
  //  Check for the payment page changes then check for charge session changes
  //  If the charge session is authorised then move on
  //

  _checkChargeSessionStatusTimeout;

  _checkChargeSessionStatus = () => {
    // Clear the timeout
    if(typeof this._checkChargeSessionStatusTimeout !== 'undefined')
      clearTimeout(this._checkChargeSessionStatusTimeout);

    this.context.checkChargeStatus(this.state.session.PAYGCode)
    .then(res => {

      const newCharge = {...this.state.charge};
      newCharge.EndTime = res.EndTime;
      this.setState({authorised: res.Authorised, charge: newCharge}, () => {

        if(res.Authorised === true){
          
          this.context.authoriseCharge(this.state.session.PAYGCode);

          this.setState({
            show: {
              ...this.state.show,
              paymentWindow: false
            }
          }, () => {

            // If the charge was made by a guest store the charge session in local storage
            if(!this.context.isLoggedIn){
              this.setState({socketState: 'C'});
              this.context.logCharge({...this.state.session, EvPointSiteID: this.state.site.id});
            }

          });
        } else {
          this._checkChargeSessionStatusTimeout = setTimeout(this._checkChargeSessionStatus, 30000);
        }

      })

    })
    .catch(err => {
      this._checkChargeSessionStatusTimeout = setTimeout(this._checkChargeSessionStatus, 30000);
      console.log('Error');
      console.log(err);
    })
  }

  reload = () => {

    const state = {
      ...this.state,
      ...defaultState
    }
    
    this.context.sessionsLoad().then(
      res => {
        this.setState(state, this._init);
      }, err => {
        this.setState(state, this._init);
      }
    )
  }

  




  //
  //
  //  Opening a closing component handlers
  //
  //


  openCheckoutMethod = () => {
      this._togglePanel('checkoutMethod', true); 
  }

  closeCheckoutMethod = () => {
    this._togglePanel('checkoutMethod', false);
  }

  openPaymentMethod = () => {
    this._togglePanel('paymentMethod', true);
  }

  closePaymentMethod = () => {
      this._togglePanel('paymentMethod', false, () => {
        if(typeof this.state.fields.time !== 'undefined' && this.state.fields.time > 0)
          this._selectSession();
      });
  }

  selectPaymentMethod = (method) => {
    this.setState({
      fields: {
        ...this.state.fields,
        paymentMethod: method
      }}, () => {
      this.closePaymentMethod();
      this.validate();
    })
  }

  openSelectTime = () => {
    this._togglePanel('selectTime', true);
  }

  closeSelectTime = () => {
    this._togglePanel('selectTime', false, () => {
      this._selectSession();
    });
  }

  openPaymentWindow = () => {
    this._togglePanel('paymentWindow', true);
  }

  closePaymentWindow = () => {
    this._togglePanel('paymentWindow', false, () => {
      this.setState({paymentUrl: null})
    });
  }

  openRegNumber = () => {
    this._togglePanel('regNumber', true);
  }

  closeRegNumber = () => {
    this._togglePanel('regNumber', false, () => {
      if(typeof this.state.session.Hours !== 'undefined' && this.state.session.Hours > 0)
        this._selectSession();
    }); 
  }

  openKwhLimit = () => {
    this._togglePanel('kwhLimit', true);
  }

  closeKwhLimit = () => {
    this._togglePanel('kwhLimit', false); 
  }

  openLoadManager = () => {
    this._togglePanel('loadManager', true);
  }

  closeLoadManager = () => {
    this._togglePanel('loadManager', false);
  }

  openWalletInfo = () => {
    this._togglePanel('walletInfo', true);
  }

  closeWalletInfo = () => {
    this._togglePanel('walletInfo', false);
  }

  openWalletTopup = () => {
    this._togglePanel('topupWallet', true);
  }

  closeWalletTopup = () => {
    this._togglePanel('topupWallet', false, () => {
      this.validate();
    })
  }

  openOperationalHours = () => {
    this._togglePanel('operationalHours', true);
  }

  closeOperationalHours = () => {
    this._togglePanel('operationalHours', false);
  }

  showError = () => {
    this._togglePanel('error', true);
  }


  closeError = () => {
    this._togglePanel('error', false);
  }


  //
  //
  //  END opening a closing component handlers
  //
  //




  //
  //  Selections and inputs
  //

  selectChargeTime = (hours) => {
    this.setState({
      fields: {
        ...this.state.fields,
        time: hours
      }
    });
  }

  selectPaymentMethod = (method) => {
    this.setState({
      fields: {
        ...this.state.fields,
        paymentMethod: method
      }}, () => {
      this.closePaymentMethod();
      this.validate();
    })
  }

  setRegistrationNumber = (vrm) => {
    this.setState({
      fields: {
        ...this.state.fields,
        vrm: vrm
      }
    }, this.validate); 
  }

  setKwhLimit = (kwh) => {
    this.setState({
      fields: {
        ...this.state.fields,
        kwhLimit: kwh
      }
    }, this.validate); 
  }


  //
  //
  //  Assiting functions
  //
  //

  validate = () => {
    //console.log('validating');

    let valid = true;
    let walletErr = false;
    let currencyErr = false;

    if(this.state.socketState === 'U' || this.state.session.Hours === 0 || (this.state.fields.vrm.length < 2 && this.state.required.vrm)){
      valid = false;
    }

    // If the basis is time and the price is 0 but it isn't yet set to free
    if((this.state.session.UnitOfMeasureType !== 'kwh' && this.state.session.Price < 0.01 && this.state.isFree === false)){
      valid = false;
    }

    
    // If the basis is time and the price is > 0 and it is not a free charge and the wallet 
    // amount is less than the price of the charge
    if(this.state.session.UnitOfMeasureType !== 'kwh' && this.state.session.Price > 0.01 && this.state.isFree === false && this.state.fields.paymentMethod === 'WALLET' && (parseFloat(this.context.wallet.balance) < this.state.session.Price)){
      valid = false;
      walletErr = true;
    }

    // If the basis is kwh and it is not a free charge and the wallet amount is less than £1
    if(this.state.session.UnitOfMeasureType === 'kwh' && this.state.session.Tariff > 0.01 && this.state.isFree === false && this.state.fields.paymentMethod === 'WALLET' && (parseFloat(this.context.wallet.balance) < 1.00)){
      valid = false;
      walletErr = true;
    }

    // If it is not a free charge and the payment method selected is wallet and the wallet currency is not the same as the tariff currency 
    if(this.state.session.Tariff > 0.01 && this.state.isFree === false && this.state.fields.paymentMethod === 'WALLET' && this.state.session.Currency !== this.context.wallet.currency){
      valid = false;
      currencyErr = true;
    }


    const errors = {
      ...this.state.errors,
      wallet: walletErr,
      currency: currencyErr
    }

    // Check for any existing errors which haven't cleared
    Object.keys(errors).map(err => {
      if(errors[err] !== false)
        valid = false;
    })

    this.setState({
      valid: valid,
      errors
    });
  }

  _setError = (type, value) => {
    let errors = {...this.state.errors}
    errors[type] = value;
    this.setState({errors: errors});
  }

  _togglePanel = (type, value, callback = () => {}) => {
    let show = {...this.state.show}
    show[type] = value;
    this.setState({show: show}, callback);
  }

  _hasVRMBeenSet = () => {
    const vrm = this.state.fields.vrm;
    if(typeof vrm === 'undefined' || vrm === '' || vrm === false || vrm === null)
      return false

    return true;
  }



  //
  //
  //  RENDER
  //
  //


  render() {

    const paygCode = () => {
      if(typeof this.state.session === 'undefined' || this.state.session === null)
        return null
      else
        return this.state.session.PAYGCode;
    }

    return (
      <Translation>{t => 
      <div style={styles.container}>
        <Header title={t('TITLE_POINT')} history={this.props.history} overrideHistory={true} />

        {(this.state.loading || !this.state.initialised) && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

        {(this.state.connector && this.state.initialised) && 
        <div style={styles.wrapper}>
          

          {this.state.socketState !== 'C' &&
          <div style={styles.content}>

            {/*
            * 
              TOP SECTION 
            *
            */}
            <div style={styles.top}>
              <div style={styles.summary}>

                {/* MARKER */}
                <div style={styles.summary_icon}>
                  <img src={this.getMarkerType().image} style={{width: 34, height: 50}} alt="Status" />
                  <span style={Object.assign({}, styles.iconText, {color: this.getMarkerType().color})}>{t(this.getMarkerType().label)}</span>
                </div>

                {/* SOCKET ID AND POWER RATING */}
                <div style={styles.summary_detail}>
                  <span style={styles.summary_name}>{this.state.site.name}</span>
                  <span style={styles.summary_tag}>{typeof this.state.payg_code !== 'undefined' ? 'UKEV'+this.state.payg_code : this.state.point.name}</span>

                  {typeof this.state.connector_no !== 'undefined' && 
                  <div style={styles.summary_info}>
                    <span style={styles.summary_power}>{t('CONNECTOR')} {this.state.connector_no}</span>
                  </div>
                  }
                  <div style={styles.summary_info}>
                    <span style={styles.summary_type}>{this.state.connector.socketType}</span>
                    <span style={styles.summary_power}>{this.state.point.provision.amps}A ({this.state.point.provision.kW}kW)</span>
                  </div>
                </div>
              </div>


              {/* NOTICES */}
              {(this.state.site.isLoadManaged || this.state.site.vlm.operationalHours.length > 0 || this.state.site.vlm.randomDelayMaxMinutes > 0) && 
                <div style={styles.notifications}>
                  <div style={styles.notifications_body}>
                    <span style={styles.notifications_title}>{t('PLEASE_NOTE')}</span>
                    {/* LOAD MANAGED MESSAGE */}
                    {this.state.site.isLoadManaged && <NotificationAccordian title={t('LOAD_MANAGED_POINT')} description={t('LOAD_MANAGED_POINT_MSG')} onClick={this.openLoadManager} icon={Images.warning_icon} />}
                    {/* OPERATIONAL HOURS */}
                    {this.state.site.vlm.operationalHours.length > 0 && <NotificationAccordian title={t('OPERATIONAL_HOURS_APPLY')} description={t('OPERATIONAL_HOURS_APPLY_DESC')} onClick={this.openOperationalHours} icon={Images.timer_warning_icon} />}
                    {/* RANDOM DELAY */}
                    {this.state.site.vlm.randomDelayMaxMinutes > 0 && <NotificationAccordian title={t('RANDOM_DELAY_NOTICE', {minutes: this.state.site.vlm.randomDelayMaxMinutes})} description={t('RANDOM_DELAY_NOTICE_DESC', {minutes: this.state.site.vlm.randomDelayMaxMinutes})} icon={Images.schedule_warning_icon} />}
                  </div>
                </div>
              }


            </div>

            

            {/* UNAVAILABLE MESSAGE */}
            {this.state.socketState === 'U' && 
              <div style={styles.unavailable}>
                  <span style={styles.load_head}>{t('CHARGE_POINT_UNAVAILABLE')}</span>
                  <a href="JavaScript:void(0);" style={styles.reloadButton} onClick={this.reload}>{t('RELOAD')}</a>
              </div>
            }
          
            

            {/* 
            *
              CHARGE SESSION FORM
            *
            */}
            {(this.state.session && this.state.socketState === 'A') && 
            <div style={styles.bottom}>


              {/* TITLE */}
            {this.state.session.Hours > 0 && <span style={styles.title}>{t('CHARGE_DETAILS')}</span>}
              {this.state.session.Hours === 0 && <span style={styles.title}>{t('SELECT_CHARGE')}</span>}

              {/* SPEED */}
              <div style={Object.assign({},styles.attribute, {marginTop: 10})}>
                <span style={styles.attributeLabel}>{this.state.site.isLoadManaged && 'Max '}{t('SPEED')}</span>
                <span style={styles.attributeValue}>{this.state.point.provision.amps}A ({this.state.point.provision.kW}kW)</span>
              </div>

              {/* SOCKET TYPE */}
              <div style={styles.attribute}>
                <span style={styles.attributeLabel}>{t('SOCKET')}</span>
                <span style={styles.attributeValue}>{this.state.connector.socketType}</span>
              </div>

              {/* SELECT TIME */}
              {this.state.session.UnitOfMeasureType !== 'kwh' && 
                <div style={styles.attribute}>
                  <span style={styles.attributeLabel}>{t('SELECT_TIME')}</span>
                  <span onClick={this.openSelectTime} style={Object.assign({}, styles.attributeValue, styles.attributeLink, (this.state.confirmPressed && this.state.session.Hours <= 0) && styles.attributeLinkError)}>{this.state.session.Hours>0?this.state.session.Hours>1?this.state.session.Hours+' '+t('HOURS'):this.state.session.Hours+' '+t('HOUR'):t('SELECT')}</span>
                </div>
              }

              {/* KWH LIMIT */}
              {this.state.session.UnitOfMeasureType === 'kwh' &&  
                <div style={styles.attribute}>
                  <span style={styles.attributeLabel}>{t('LIMIT_CHARGE')}</span>
                  <span onClick={this.openKwhLimit} style={Object.assign({}, styles.attributeValue, styles.attributeLink, (this.state.confirmPressed && this.state.fields.kwh === '') && styles.attributeLinkError)}>{this.state.fields.kwhLimit?`${this.state.fields.kwhLimit} ${t('KWH')}`:t('NO_LIMIT')}</span>
                </div>
              }

              {/* VRM */}
              {this.state.required.vrm && 
                <div style={styles.attribute}>
                  <span style={styles.attributeLabel}>{t('VEHICLE')}</span>
                  <span onClick={this.openRegNumber} style={Object.assign({}, styles.attributeValue, styles.attributeLink, (this.state.confirmPressed && this.state.fields.vrm === '') && styles.attributeLinkError)}>{this.state.fields.vrm?this.state.fields.vrm:t('ENTER_REG')}</span>
                </div>
              }

              {/* PAYMENT METHOD */}
              <div style={styles.attribute}>

                <span style={styles.attributeLabel}>{this.context.isLoggedIn ? t('PAYMENT_METHOD')+':' : t('CHARGE_AS')}</span>
                
                {/* SIGNIN OR GUEST */}
                {(!this.context.isLoggedIn && !this.context.isGuestCheckout) && 
                  <React.Fragment>
                    {(!this.state.isFree && this.state.session.Tariff > 0) && <span onClick={this.openCheckoutMethod} style={Object.assign({}, styles.attributeValue, styles.attributeLink)}>{t('SIGNIN_OR_GUEST')}</span>}
                    {(this.state.isFree && this.state.session.Price === 0) && <span style={styles.attributeValue}>{t('FREE')}</span>}
                  </React.Fragment>
                }
                

                {/* LOGGED IN SELECT WALLET OR PAYG */}
                {this.context.isLoggedIn &&  
                  <span style={styles.attributeValue}>

                    {/* If the charge is not free and the charge point is not group manager */}
                    {(!this.state.isFree && this.state.session.Tariff > 0 && !this.state.isGM) && <span onClick={this.openPaymentMethod} style={Object.assign({}, styles.attributeValue, styles.attributeLink)}>
                      {t(this.state.fields.paymentMethod)}
                      {this.state.fields.paymentMethod === 'WALLET' && ' (' + parseFloat(this.context.wallet.balance).toLocaleString(navigator.language, {style: 'currency', currency: this.context.wallet.currency}) +')'}
                    </span>}

                    {(!this.state.isFree && this.state.session.Tariff > 0 && this.state.isGM) && <span style={styles.attributeValue}>{t('PAYG')}</span>}

                    {(this.state.isFree && this.state.session.Price === 0) && <span style={styles.attributeValue}>{t('FREE')}</span>}
                  </span>
                }

                {/* GUEST CHARGE */}
                {this.context.isGuestCheckout && <span style={styles.attributeValue}>({t('GUEST')}){(!this.state.isFree && this.state.session.Price > 0) && ' - ' + t('PAYG') }</span>}
              </div>



              {/* 
              *
                BOTTOM OF PAGE NOTICES 
              *
              */}

              {/* General form errors message */}
              {(this.state.confirmPressed && this.state.session.Hours <= 0 && this.state.session.UnitOfMeasureType !== 'kwh') && <span style={styles.errorNotice}>{t('SELECT_TIME_NOTICE')}</span>}

              {/* Vehicle registration required notice */}
              {(this.state.required.vrm && this.state.fields.vrm.length < 2) && <span style={this.state.confirmPressed ? styles.errorNotice : styles.infoNotice}>{t('POINT_VEHICLE_REG_NOTICE')}</span>}
              
              {/* Message displayed if there was an error selecting the amount of time. */}
              {this.state.errors.time !== false && <span style={styles.infoNotice}>{t(this.state.errors.time)}</span>}

              {/* Show the cost if the tariff is per hour */}
              {(!this.state.isFree && this.state.session.Price > 0 && this.state.session.Hours > 0 && this.state.session.UnitOfMeasureType === 'time') && 
                <div>
                  <span style={styles.cost}>{t('COST')}: {parseFloat(this.state.session.Price).toLocaleString(navigator.language, {style: 'currency', currency: this.state.session.Currency})}</span>

                  {/* SERVICE CHARGE REMOVED */}   
                  {/* {(this.state.fields.paymentMethod !== 'WALLET' && parseFloat(this.state.session.Price) < 5) && <span style={styles.infoNotice}>{t('SERVICE_CHARGE_TIME')} {!this.state.isGM && <a href="javascript:void(0);" style={styles.infoNoticeLink} onClick={this.openWalletInfo}>{t('TAP_WALLET_INFO')}</a>}</span>} */}
                
                  {this.state.errors.wallet && <span style={styles.infoNotice}>{t('NOT_ENOUGH_FUNDS')}</span>}
                  {this.state.errors.currency && <span style={styles.infoNotice}>{t('CURRENCY_MISMATCH')}</span>}
                </div>
              }

              {/* Show the cost if the tariff is per kWh */}
              {(!this.state.isFree && this.state.session.Tariff > 0 && this.state.session.Hours > 0 && this.state.session.UnitOfMeasureType === 'kwh') && 
                <div>
                  <span style={styles.cost}>{parseFloat(this.state.session.Tariff).toLocaleString(navigator.language, {style: 'currency', currency: this.state.session.Currency})}
                    <span style={styles.perkwh}>&nbsp;per kWh</span>
                  </span>
                  
                  {/* SERVICE CHARGE REMOVED */}
                  {/* {this.state.fields.paymentMethod !== 'WALLET' && <span style={styles.infoNotice}>{t('SERVICE_CHARGE_KWH')} {!this.state.isGM && <a href="javascript:void(0);" style={styles.infoNoticeLink} onClick={this.openWalletInfo}>{t('TAP_WALLET_INFO')}</a>}</span>} */}
                  {this.state.isGM && <span style={styles.infoNotice}>{t('WALLET_PAYMENT_UNAVAILABLE')}</span>}
                  {this.state.fields.paymentMethod !== 'WALLET' && <span style={styles.authNotice}>{t('MAX_SPEND_NOTICE', {amount: Helpers.localeCurrency(100, this.state.session.Currency)})}</span>}
                  {(this.state.fields.paymentMethod === 'WALLET' && !this.state.errors.wallet && !this.state.errors.currency) && <span style={styles.maxChargeNotice}>{t('WALLET_MAX_SPEND')} <span style={{fontWeight: 900}}>{Math.floor(parseFloat(this.context.wallet.balance) / parseFloat(this.state.session.Tariff))}kWh</span>. <a href="javascript:void(0);" onClick={this.openWalletTopup} style={{color: '#6CB33F', fontWeight: 900}}>{t('TOPUP')}</a></span>}
                  {(this.state.fields.paymentMethod === 'WALLET' && this.state.errors.wallet) && <span style={{color: '#cca200', textAlign: 'center', display: 'block', marginTop: 10}}>{t('WALLET_MIN_AMOUNT', {amount: Helpers.localeCurrency(1, this.state.session.Currency)})}</span>}
                  {this.state.errors.currency && <span style={styles.infoNotice}>{t('CURRENCY_MISMATCH')}</span>}
                </div>
              }

              {/* Show if the tariff is free */}
              {(this.state.isFree && this.state.session.Hours > 0) && <div><span style={styles.cost}>{t('FREE_CHARGE')}</span></div>}
              

              {/* CONFIRM CHARGE OR TOP UP */}
              <div style={styles.confirm}>
                {!this.state.errors.wallet && <a href="JavaScript:void(0);" style={Object.assign({}, styles.confirmButton, (this.state.valid && (this.context.isLoggedIn || this.context.isGuestCheckout || this.state.isFree)) && styles.confirmButtonValid)} disabled={!this.state.valid || (!this.context.isLoggedIn && !this.context.isGuestCheckout)} onClick={this.confirmCharge}><span style={styles.confirmButtonText}>{t('CONFIRM_CHARGE')} {!this.state.isFree && t('AND_PAY')}</span></a>}
                {this.state.errors.wallet && <a href="JavaScript:void(0);" style={Object.assign({}, styles.confirmButton, this.context.isLoggedIn && styles.confirmButtonValid)} disabled={!this.context.isLoggedIn} onClick={this.openWalletTopup}><span style={styles.confirmButtonText}>{t('TOPUP')}</span></a>}
              </div>
            </div>}
          </div>
          }

          
          {/* ACTIVE CHARGE */}
          {this.state.socketState == 'C' && <ActiveCharge site={this.state.site} point={this.state.point} connector={this.state.connector} reload={this.reload} />}

        </div>
        }

        {/* SELECTIONS AND INPUTS */}
        {this.state.session !== null && <SelectTime show={this.state.show.selectTime} selectChargeTime={this.selectChargeTime} onClickClose={this.closeSelectTime} time={this.state.fields.time} />}
        {(this.state.session !== null && !this.context.isLoggedIn && !this.context.isGuestCheckout) && <SignInOrGuest show={this.state.show.checkoutMethod} closeConfirm={this.closeCheckoutMethod} />}
        {this.state.session !== null && <PaymentWindow show={this.state.show.paymentWindow} closeConfirm={this.closePaymentWindow} onChange={this._checkChargeSessionStatus} paymentUrl={this.state.paymentUrl} amount={this.state.session.Price} currency={this.state.session.Currency} tariff={this.state.session.Tariff} UnitOfMeasureType={this.state.session.UnitOfMeasureType} />}
        <NumberPlateInput onShow={this.state.show.regNumber} onClose={this.closeRegNumber} reg={this.state.fields.vrm} onSetReg={this.setRegistrationNumber} />
        <SelectKwhLimit onShow={this.state.show.kwhLimit} onClose={this.closeKwhLimit} kwh={this.state.fields.kwhLimit} onSetKwh={this.setKwhLimit} code={paygCode()} />
        <PaymentMethodSelect show={this.state.show.paymentMethod} onClose={this.closePaymentMethod} onSelect={this.selectPaymentMethod} paymentMethod={this.state.fields.paymentMethod} />
        <TopupWallet show={this.state.show.topupWallet} close={this.closeWalletTopup} />

        {/* DIALOGS */}
        <LoadManagedDialog show={this.state.show.loadManager} onClose={this.closeLoadManager} />
        <WalletInfoDialog show={this.state.show.walletInfo} onClose={this.closeWalletInfo} />
        {this.state.site !== null && <OperationalHours show={this.state.show.operationalHours} onClose={this.closeOperationalHours} site={this.state.site} />}
        <ErrorDialog show={this.state.show.error} onClose={this.closeError} />

      </div>
      }</Translation>
    );
  }

}


const styles = {
  container: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
  },
  content: {
    padding: 20,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  top: {
    borderBottomWidth: 3,
    borderBottomColor: '#6CB33F',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    width: '100%',
    maxWidth: 500
  },
  bottom: {
    padding: 20,
    width: '100%',
    maxWidth: 500
  },
  summary: {
    width: '86%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 120
  },
  summary_icon: {
      height: '100%',
      width: 100,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
  },
  iconText: {
    fontSize: 10
  },
  summary_detail: {
      height: '100%',
      flex: 1,
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 20,
      paddingBottom: 20
  },
  summary_name: {
      color: '#888888',
      fontSize: 16,
      fontWeight: '400',
      marginBottom: 5
  },
  summary_tag: {
      color: '#484848',
      fontSize: 20,
      fontWeight: '900',
      marginBottom: 5
  },
  summary_info: {
    flexDirection: 'row',
    display: 'flex'
  },
  summary_type: {
      flex: 1,
      color: '#484848',
      fontSize: 16
  },
  summary_power: {
      flex: 1,
      color: '#484848',
      fontSize: 16
  },
  notifications: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #cca200',
    backgroundColor: '#f6f2e3',
    borderRadius: 3,
    marginTop: 5,
  },
  notifications_body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '6px 12px'
  },
  notifications_title: {
    fontSize: 14,
    fontWeight: 600,
    color: '#484848'
  },
  confirm: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 30
  },
  confirmButton: {
    backgroundColor: '#e2e2e2',
    width: 240,
    height: 40,
    borderRadius: 6,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    cursor: 'pointer'
  },
  confirmButtonValid: {
    backgroundColor: '#464646'
  },
  confirmButtonText: {
    color: '#fff',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  title: {
    color: '#484848',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 10
  },
  attribute: {
    width: '100%',
    display: 'flex',
    height: 50,
    flexWrap:'wrap',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: '#efefef',
    alignItems: 'center',
    flexDirection: 'row',
  },
  attributeLabel: {
      flex: 1
  },
  attributeLink: {
      color: '#6CB33F',
      fontWeight: 900,
      cursor: 'pointer',
      transition: 'all ease-out .3s'
  },
  attributeLinkError: {
    color: '#8E0000',
    textDecoration: 'underline'
  },
  attributeValue: {
      flex: 1,
      textAlign: 'right'
  },
  cost: {
    fontSize: 28,
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'center',
    marginTop: 30,
    color: '#6CB33F',
    marginBottom: 4
  },
  surcharge: {
    fontSize: 12,
    display: 'block',
    textAlign: 'center',
    color: '#6CB33F',
    marginBottom: 4, 
    marginTop: 10
  },
  authNotice: {
    fontSize: 12,
    display: 'block',
    textAlign: 'center',
    color: '#484848', 
    marginTop: 10
  },
  infoNotice: {
    fontSize: 12,
    color: '#cca200', 
    textAlign: 'center', 
    display: 'block', 
    marginTop: 10
  },
  infoNoticeLink: {
    fontWeight: 900,
    color: '#cca200',
    textDecoration: 'none'
  },
  errorNotice: {
    fontSize: 12,
    color: '#8E0000', 
    textAlign: 'center', 
    display: 'block', 
    marginTop: 10
  },
  maxChargeNotice: {
    fontSize: 14,
    display: 'block',
    textAlign: 'center',
    color: '#484848'
  },
  perkwh: {
    display: 'inline',
    fontSize: 12,
    textAlign: 'center',
    color: '#6CB33F',
    fontWeight: 600
  },
  loadingWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,.4)',
    flex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeCharge: {
    color: '#6CB33F', 
    fontSize: 20, 
    fontWeight: 900,
    width: '80%',
    marginTop: 30,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems:'center'
  },
  reload: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: 30
  },
  reloadButton: {
    backgroundColor: '#464646',
    width: 180,
    height: 30,
    borderRadius: 6,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    cursor: 'pointer',
    fontSize: 14,
    color: '#fff',
    textAlign: 'center',
    fontWeight: 400,
    marginTop: 15
  },
  endButton: {
    backgroundColor: '#464646',
    width: 180,
    height: 30,
    borderRadius: 6,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    cursor: 'pointer'
  },
  endButtonConfirmWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  endButtonConfirm: {
    backgroundColor: '#cc0000',
    width: 180,
    height: 30,
    borderRadius: 6,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    cursor: 'pointer'
  },
  endButtonText: {
    fontSize: 14,
    color: '#fff',
    textAlign: 'center',
    fontWeight: 400
  },
  endButtonConfirmText: {
    fontSize: 12,
    color: '#cc0000',
    textAlign: 'center',
    fontWeight: 400,
    marginTop: 10
  },
  unavailable: {
    padding: 20,
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
};


export default SocketScreen;