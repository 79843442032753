import React, { useEffect, useState } from 'react';
import Images from '../../assets/images/index';
import ActivityIndicator from '../global/ActivityIndicator';
import RegisterForm from './RegisterForm';
import GuestCheckoutForm from './GuestCheckoutForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import { Translation } from 'react-i18next';
import { login } from '../../actions/userActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';


const height = window.innerHeight;
const width = window.innerWidth;


const LoginScreen = props => {

  const history = useHistory();

  const [fields, setFields] = useState({
    username: '',
    password: '',
  });


  const errorField = (group = null) => {
    return {
      dirty: false,
      invalid: false,
      group: group
    };
  }

  const [errors, setErrors] = useState({
    username: errorField(),
    password: errorField()
  });

  const [isValid, setIsValid] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [allowGuest, setAllowGuest] = useState(typeof props.allowGuest !== 'undefined' ? props.allowGuest : false);


  const bindField = (e) => {
    let newFields = { ...fields };
    newFields[e.target.name] = e.target.value;
    setFields(newFields);
  }

  useEffect(() => {
    validate();
  }, [fields])


  const dirtyGroup = (group = null, callback = () => { }) => {
    let newErrors = { ...errors };
    Object.values(newErrors).map(e => {
      if (e.group === group)
        e.dirty = true;
    })
    setErrors(newErrors);
    //setState({errors: errors}, () => {callback()});
  }

  const validate = (callback = () => { }) => {
    let newErrors = { ...errors };
    const required = [
      'username',
      'password'
    ]

    required.map(f => {
      if (fields[f].length > 0)
        newErrors[f].dirty = true;

      newErrors[f].invalid = false;
      if (newErrors[f].dirty && fields[f] === '') {
        newErrors[f].invalid = true;
      }
    })

    const isValid = Object.values(newErrors).findIndex(e => e.invalid === true) === -1;
    setErrors(newErrors);
    setIsValid(isValid);
    //setState({errors: errors, isValid: isValid}, () => {callback()});
  }


  const login = () => {

    if (isValid)
      props.login(fields);

  }

  useEffect(() => {

    if (props.user.status === 'in') {

      if (typeof props.onClose !== 'undefined')
        props.onClose();


      if (typeof history !== 'undefined') {

        // if using isAuth then the previous screen should appear
        if (!props.isAuth) {
          if (history.action === 'POP') {
            history.push('/');
          } else {
            history.goBack();
          }
        }

      }

    }

  }, [props.user])

  const registerRedirect = () => {
    if (!props.isAuth) {
      if (history.action === 'POP') {
        history.push('/');
      } else {
        history.goBack();
      }
    }
  }

  const onClickRegisterHandler = () => {
    setShowRegister(true);
  }

  const onClickCloseRegisterHandler = () => {
    setShowRegister(false);
  }

  const onClickGuestHandler = () => {
    setShowGuest(true);
  }

  const onClickCloseGuestHandler = () => {
    setShowGuest(false);
  }

  const onClickForgotHandler = () => {
    setShowForgot(true);
  }

  const onClickCloseForgotHandler = () => {
    setShowForgot(false);
  }

  const onClickCloseHandler = () => {

    if (typeof props.onClose !== 'undefined') {
      props.onClose();
    }

    if (typeof history !== 'undefined') {
      if (history.action === 'POP') {
        history.push('/');
      } else {
        history.goBack();
      }
    }

  }

  return (
    <Translation>{t =>
      <div style={styles.container}>
        <div style={styles.bgImg}>


          <button style={styles.close} onClick={onClickCloseHandler}><img src={Images.close_btn} style={{ width: 30, height: 30 }} alt="Close" /></button>

          <div style={styles.loginWrapper}>
            <img src={Images.vend_logo} style={styles.vendLogo} alt="vendelectric" />

            {props.user.verifying && <div style={styles.loadingWrapper}><div style={styles.loading}><ActivityIndicator /></div></div>}

            <div>
              {props.user.msg && <span style={styles.loginError}>{t('LOGIN_ERR')}</span>}

              <div style={Object.assign({}, styles.inputWrapper, errors.username.invalid && styles.inputError)}>
                <input id="username" type={'email'} name={'username'} onChange={bindField} placeholder={t('EMAIL')} style={styles.input} value={fields.username} autoComplete="off" />
              </div>
              <div style={Object.assign({}, styles.inputWrapper, errors.password.invalid && styles.inputError)}>
                <input id="password" type={'password'} name={'password'} onChange={bindField} placeholder={t('PASSWORD')} style={styles.input} autoComplete="off" />
              </div>

              <div style={styles.forgotPassword}>
                <span>{t('FORGOTTEN_PASSWORD')} </span><a href="JavaScript:void(0)" onClick={onClickForgotHandler}><span style={{ fontWeight: 'bold', color: '#484848' }}>&nbsp;{t('RESET')}</span></a>
              </div>

              <div style={styles.buttonGroup}>
                <button id="loginButton" style={Object.assign({}, styles.buttonGroupBtn, styles.buttonGroupBtnPrimary)} onClick={login}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('LOGIN')}</span></button>
                <button id="registerButton" style={styles.buttonGroupBtn} onClick={onClickRegisterHandler}><span style={{ color: '#ffffff', textTransform: 'uppercase' }}>{t('REGISTER')}</span></button>
              </div>

              <div style={styles.footerLinks}>
                {allowGuest && <a href="JavaScript:void(0)" style={styles.guestCheckout} onClick={onClickGuestHandler}><span>{t('CHARGE_AS_GUEST')}</span></a>}
                <a href="https://www.rolecserv.com/vendelectric" target="_blank" style={styles.appSupport}><span>{t('APP_SUPPORT')}</span></a>
              </div>
            </div>
          </div>

          <div style={styles.rolecLogoWrapper}>
            <img src={Images.rolec_logo} style={styles.rolecLogo} alt="Powered by Rolec" />
          </div>

        </div>

        <RegisterForm close={onClickCloseRegisterHandler} show={showRegister} redirect={registerRedirect}></RegisterForm>
        <ForgotPasswordForm close={onClickCloseForgotHandler} show={showForgot}></ForgotPasswordForm>
        <GuestCheckoutForm close={onClickCloseGuestHandler} show={showGuest} onSuccess={props.onClose}></GuestCheckoutForm>

      </div>
    }</Translation>
  );

}


const styles = {
  container: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  },
  bgImg: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundImage: 'url(' + Images.bg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    overflowY: 'auto'
  },
  vendLogo: {
    width: '80%',
    maxWidth: 250,
    maxHeight: 58,
    marginBottom: 40,
    alignSelf: 'center'
  },
  loginWrapper: {
    width: '98%',
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column'
  },
  loginError: {
    color: '#F26A6A',
    fontSize: 14,
    marginBottom: 15,
    textAlign: 'center',
    display: 'block'
  },
  inputWrapper: {
    height: 54,
    width: '100%',
    backgroundColor: '#ebebeb',
    borderRadius: 12,
    marginBottom: 10,
    justifyContent: 'center',
    display: 'flex'
  },
  inputError: {
    boxShadow: 'inset 0 0 0 1px #F26A6A'
  },
  inputErrorMsg: {
    color: '#F26A6A',
    fontSize: 11,
    paddingLeft: 20,
    marginBottom: 8,
    marginTop: -8
  },
  forgotPassword: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonGroup: {
    width: '100%',
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonGroupBtn: {
    width: 125,
    height: 50,
    backgroundColor: '#555555',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer'
  },
  buttonGroupBtnPrimary: {
    backgroundColor: '#6CB33F'
  },
  footerLinks: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  appSupport: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginTop: 10,
    color: '#484848'
  },
  guestCheckout: {
    flex: 1,
    marginTop: 15,
    color: '#6CB33F',
    fontWeight: '900'
  },
  rolecLogoWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingBottom: 20,
  },
  rolecLogo: {
    // position: 'absolute',
    // bottom: 30,
    // right: 30,
    width: 110,
    height: 25,
    paddingRight: 20,
  },
  loadingWrapper: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 99,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, .6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    backgroundColor: '#6CB33F',
    opacity: .7,
    borderRadius: 25,
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    fontSize: 16,
    flex: 1,
    paddingLeft: 20
  },
  close: {
    // position: 'absolute',
    // top: 0,
    // right: 0,
    marginTop: 50,
    marginRight: 20,
    border: 'none',
    backgroundColor: 'transparent',
    height: 50,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    zIndex: 90,
    color: '#6CB33F'
  },
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);